<style scoped>
.tips {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
}
</style>
<template>
  <div ref="root" :style="control.style">
    <div v-if="!isView" class="tips" v-text="tips"></div>
  </div>
</template>

<script>
import * as THREE from "three";
export default {
  props: { control: Object, isView: Boolean },
  data() {
    return {
      tips: "",
      renderer: null,
      vertexShader: [
        "precision mediump float;",
        "precision mediump int;",
        "uniform mat4 modelViewMatrix;",
        "uniform mat4 projectionMatrix;",
        "attribute vec3 position;",
        "varying vec3 vPosition;",
        "void main()",
        "{",
        "    vPosition = position;",
        "    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);",
        "}",
      ].join("\n"),
      fragmentShader: [
        "precision mediump float;",
        "precision mediump int;",
        "const int size = {POINT_COUNT};",
        "uniform vec3 points[size];",
        "uniform float alpha;",
        "uniform float radius;",
        "varying vec3 vPosition;",
        "vec4 getColor(float percent)",
        "{",
        "    float val = percent * 3.0 * 256.0;",
        "    if (val < 0.0)",
        "    {",
        "        return vec4(0.0, 0.0, 1.0, alpha);",
        "    }",
        "    if (val < 256.0)",
        "    {",
        "        return vec4(0.0, val / 255.0, 1.0, alpha);",
        "    }",
        "    if (val < 512.0)",
        "    {",
        "        return vec4((val - 256.0) / 255.0, 1.0, (511.0 - val) / 255.0, alpha);",
        "    }",
        "    if (val < 768.0)",
        "    {",
        "        return vec4(1.0, (767.0 - val) / 255.0, 0.0, alpha);",
        "    }",
        "    return vec4(1.0, 0.0, 0.0, alpha);",
        "}",
        "void main()",
        "{",
        "    vec2 position = vec2(vPosition.x, vPosition.y);",
        "    float vals[size];",
        "    for (int i = 0; i < size; i++)",
        "    {",
        "        vec3 point = points[i];",
        "        vec2 location = vec2(point.x, point.y);",
        "        float value = point.z;",
        "        float affect = abs(value - 0.5) * 2.0 * radius;",
        "        if (affect > 0.0)",
        "        {",
        "            float dis = distance(position, location);",
        "            if (dis < affect)",
        "            {",
        "                vals[i] = (1.0 - dis / affect) * (value - 0.5);",
        "            }",
        "        }",
        "    }",
        "    float maxVal = 0.0;",
        "    float minVal = 0.0;",
        "    for (int i = 0; i < size; i++)",
        "    {",
        "        maxVal = max(vals[i], maxVal);",
        "        minVal = min(vals[i], minVal);",
        "    }",
        "    gl_FragColor = getColor(0.5 + maxVal + minVal);",
        "}",
      ].join("\n"),
    };
  },
  mounted() {
    this.change();
  },
  beforeDestroy() {
    if (this.renderer) {
      this.renderer.domElement.removeEventListener(
        "mousemove",
        this.onMouseMove,
        false
      );
      this.renderer.forceContextLoss();
      this.renderer.dispose();
      this.$refs.root.removeChild(this.renderer.domElement);
      this.renderer = null;
    }
  },
  watch: {
    "control.size": {
      handler() {
        this.change();
      },
      deep: true,
    },
    "control.config": {
      handler() {
        this.change();
      },
      deep: true,
    },
  },
  methods: {
    change() {
      let width = this.control.size.width;
      let height = this.control.size.height;
      let alpha = 1.0;
      let min = parseFloat(this.control.config.min || "0");
      let max = parseFloat(this.control.config.max || "50");
      let points = [];
      let pointsData = this.control.config.pointsData.split(",");
      let arr = this.control.config.points.split("\n");
      for (let i in arr) {
        if (arr[i]) {
          let token = arr[i].split(",");
          if (token.length == 2) {
            let x = parseFloat(token[0]) || 0.0;
            let y = parseFloat(token[1]) || 0.0;
            let z = parseFloat(pointsData[i]);
            if (!z && z !== 0.0) z = (max - min) / 2.0;
            points.push([x, y, z]);
          }
        }
      }
      if (this.renderer) {
        this.renderer.domElement.removeEventListener(
          "mousemove",
          this.onMouseMove,
          false
        );
        this.renderer.forceContextLoss();
        this.renderer.dispose();
        this.$refs.root.removeChild(this.renderer.domElement);
        this.renderer = null;
      }
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(width, height);
      this.$refs.root.appendChild(this.renderer.domElement);
      this.renderer.domElement.addEventListener(
        "mousemove",
        this.onMouseMove,
        false
      );

      let scene = new THREE.Scene();

      let camera = new THREE.OrthographicCamera(
        -width / 2 + 1,
        width / 2 - 1,
        height / 2 - 1,
        -height / 2 + 1,
        0,
        100
      );
      camera.position.z = 100;
      scene.add(camera);

      let pointCount = points.length || 1;
      let planeGeometry = new THREE.PlaneGeometry(width, height);
      let planeMaterial = new THREE.RawShaderMaterial({
        uniforms: {
          points: {
            value: (function () {
              let result = [];
              for (let i in points) {
                let x = points[i][0] - width / 2.0;
                let y = height / 2.0 - points[i][1];
                let z = (points[i][2] - min) / (max - min);
                result.push(new THREE.Vector3(x, y, z));
              }
              if (result.length == 0) {
                result.push(new THREE.Vector3(0, 0, 0.5));
              }
              return result;
            })(),
          },
          alpha: { value: alpha },
          radius: { value: Math.max(width, height) },
        },
        vertexShader: this.vertexShader,
        fragmentShader: this.fragmentShader.replace(
          "{POINT_COUNT}",
          pointCount
        ),
        transparent: true,
      });
      let plane = new THREE.Mesh(planeGeometry, planeMaterial);
      scene.add(plane);

      if (!this.isView) {
        let pointGeometry = new THREE.CircleGeometry(3, 16);
        let pointMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
        for (let i in points) {
          let point = new THREE.Mesh(pointGeometry, pointMaterial);
          point.position.x = points[i][0] - width / 2.0;
          point.position.y = height / 2.0 - points[i][1];
          point.position.z = 1;
          scene.add(point);
        }
      }

      this.renderer.render(scene, camera);
    },
    onMouseMove(e) {
      this.tips = `${e.layerX},${e.layerY}`;
    },
  },
};
</script>
